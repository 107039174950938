<template>
  <MediaImage
    v-bind="media"
    class="carousel-images-item relative select-none bg-grey-light-01"
    :class="[aspectRatio, 'is-' + size]"
    img-class="bg-grey-light-01"
    :image-style="imageStyle"
  />
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#build/graphql-operations'

const props = defineProps<{
  media: MediaImageFragment
  moduloIndex: number
}>()

const aspectRatio = computed(() => {
  if (props.moduloIndex === 0) {
    return 'aspect-square lg:aspect-[2/3]'
  } else if (props.moduloIndex === 1) {
    return 'aspect-[2/3] lg:aspect-square'
  }
  return 'aspect-[3/4]'
})

const imageStyleOne = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 164,
      height: 164,
    },
    md: {
      width: 260,
      height: 390,
    },
    lg: {
      width: 236,
      height: 354,
    },
    xl: {
      width: 261,
      height: 392,
    },
    xxl: {
      width: 306,
      height: 459,
    },
  },
})

const imageStyleTwo = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 164,
      height: 246,
    },
    md: {
      width: 260,
      height: 390,
    },
    lg: {
      width: 236,
      height: 236,
    },
    xl: {
      width: 261,
      height: 261,
    },
    xxl: {
      width: 306,
      height: 306,
    },
  },
})

const imageStyleThree = defineImageStyle({
  type: 'pictures',
  pictures: {
    sm: {
      width: 164,
      height: 219,
    },
    md: {
      width: 260,
      height: 347,
    },
    lg: {
      width: 324,
      height: 432,
    },
    xl: {
      width: 356,
      height: 475,
    },
    xxl: {
      width: 416,
      height: 555,
    },
  },
})

const imageStyle = computed(() => {
  if (props.moduloIndex === 0) {
    return imageStyleOne
  } else if (props.moduloIndex === 1) {
    return imageStyleTwo
  }
  return imageStyleThree
})

const size = computed(() => (props.moduloIndex === 2 ? 'large' : 'small'))
</script>

<style lang="postcss">
.carousel-images {
  --column-gap: 16px;
  --column-width: 70px;

  @screen lg {
    --column-gap: 24px;
    --column-width: calc((100vw - var(--column-gap) * 13) / 14);
  }

  @screen xl {
    --column-width: 71px;
  }

  @screen xxl {
    --column-width: 86px;
  }
}

.carousel-images-item {
  flex: 0 0 164px;

  @screen md {
    flex: 0 0 260px;
  }

  @screen lg {
    flex: 0 0 calc(var(--column-width) * 3 + var(--column-gap) * 2);

    &.is-large {
      flex: 0 0 calc(var(--column-width) * 4 + var(--column-gap) * 3);
    }
  }
}
</style>
