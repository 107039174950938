
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_product_index from '/app/components/Paragraph/Product/index.vue'
import BlokkliComponent_from_library_index from '/app/node_modules/blokkli-beta/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_content_text_image_index from '/app/components/Paragraph/ContentTextImage/index.vue'
import BlokkliComponent_carousel_index from '/app/components/Paragraph/Carousel/index.vue'
import BlokkliComponent_home_stage_teaser_index from '/app/components/Paragraph/HomeStageTeaser/index.vue'
import BlokkliComponent_movement_details_index from '/app/components/Paragraph/MovementDetails/index.vue'
import BlokkliComponent_gallery_grid_index from '/app/components/Paragraph/GalleryGrid/index.vue'
import BlokkliComponent_image_index from '/app/components/Paragraph/Image/index.vue'
import BlokkliComponent_switcher_index from '/app/components/Paragraph/Switcher/index.vue'
import BlokkliComponent_text_index from '/app/components/Paragraph/Text/index.vue'
import BlokkliComponent_text_image_index from '/app/components/Paragraph/TextImage/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/app/node_modules/blokkli-beta/dist/runtime/components/Blocks/Fragment/index.vue'
import BlokkliComponent_video_index from '/app/components/Paragraph/Video/index.vue'

const global: Record<string, any> = {
  block_product: BlokkliComponent_product_index,
  block_from_library: BlokkliComponent_from_library_index,
  block_content_text_image: BlokkliComponent_content_text_image_index,
  block_carousel: BlokkliComponent_carousel_index,
  block_home_stage_teaser: BlokkliComponent_home_stage_teaser_index,
  block_movement_details: BlokkliComponent_movement_details_index,
  block_gallery_grid: BlokkliComponent_gallery_grid_index,
  block_image: BlokkliComponent_image_index,
  block_switcher: BlokkliComponent_switcher_index,
  block_text: BlokkliComponent_text_index,
  block_text_image: BlokkliComponent_text_image_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index,
  block_video: BlokkliComponent_video_index
}

    
import BlokkliFragmentComponent_collection_slider from '/app/components/Paragraph/BlokkliFragment/CollectionSlider/index.vue'

const globalFragments: Record<string, any> = {
  fragment_collection_slider: BlokkliFragmentComponent_collection_slider
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare'),
  heavy: () => import('#blokkli/chunk-heavy')
}

const chunkMapping: Record<string, string> = {
  "block_related_slider": "rare",
  "block_related_slider_list": "heavy",
  "block_switcher_item": "rare"
}

const fragmentChunkMapping: Record<string, string> = {
  "fragment_limited_edition_slider": "heavy"
}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
